import React, { useState, memo } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { overrideTailwindClasses } from "tailwind-override"
// import { useSwipeable } from "react-swipeable"

import Headroom from "react-headroom"
import CartButton from "../../features/cart/CartButton"
import { useBreakpoint } from "../../utils/useTailwindBreakpoint"

import "./styles.css"
import logo from "../../images/logo.png"

const nav = {
  Home: "/",
  About: "/about",
  Services: "/services",
  Products: "/products",
  Contact: "/get-started#contact",
  "Get Started": "/get-started",
}

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  // const [offsetX, setOffsetX] = useState(0)
  // const [swiping, setSwiping] = useState(false)
  // 2xl:text-xl 2xl:px-6 2xl:py-5
  const navItemDefault = "block p-4 lg:py-3 xl:text-lg  whitespace-nowrap"
  const navItemClass = "text-blue-dark ml-4 lg:ml-0"
  const navItemCTAClass =
    "bg-blue-50 text-blue-800 mx-4 lg:mr-0 rounded 2xl:px-8"

  const isMobile = !useBreakpoint("lg")
  const translateMenu = isMobile ? `${!menuOpen * 100}% ` : `0px`

  // Add swipe events to document
  // const { ref } = useSwipeable({
  //   onSwipeStart: () => setSwiping(true),
  //   onSwiped: e => {
  //     // console.log(e)
  //     if (isMobile && e.absX > 50) {
  //       // if (e.dir === "Left") setMenuOpen(true)
  //       // if (e.dir === "Right") setMenuOpen(false)
  //     }
  //     setSwiping(false)
  //     setOffsetX(0)
  //   },
  //   onSwiping: e => {
  //     // if (!menuOpen && e.dir === "Left")
  //     //   setOffsetX(Math.min(e.absX, MAX_MENU_WIDTH))
  //     // if (menuOpen && e.dir === "Right")
  //     //   setOffsetX(-Math.min(e.absX, MAX_MENU_WIDTH))
  //   },
  // })

  // useEffect(() => {
  //   ref(document)
  // }, [ref])

  return (
    <Headroom upTolerance={10} downTolerance={10} disableInlineStyles>
      <div className="relative bg-white centered-container">
        <div
          className={`flex items-center justify-between py-4  ${
            menuOpen && "menu-open"
          }`}
        >
          <Link to="/" className="mr-auto">
            <img
              className="h-12 lg:h-16 2xl:h-20"
              src={logo}
              alt="Smart Energy Bahamas Logo"
            />
          </Link>

          {/* Overlay */}
          <div
            className="fixed top-0 left-0 right-0 z-40 items-end h-screen opacity-0 bg-gray-900/60 menu__overlay lg:hidden"
            onClick={() => setMenuOpen(false)}
            role="none"
          ></div>

          <div className="flex gap-4">
            {/* Menu (slide on sm) */}
            <nav
              className={`fixed top-0 right-0 z-40 w-11/12 h-screen max-w-sm pt-4 bg-white lg:static lg:h-auto lg:max-w-none lg:w-auto lg:pt-0 my-menu ${
                isMobile && "duration-300"
              }`}
              style={{
                transform: `translateX(${translateMenu})`,
              }}
            >
              <ul className="flex flex-col lg:flex-row">
                {/* Nav items */}
                {Object.entries(nav).map(([title, to], i, arr) => (
                  <li key={title}>
                    <Link
                      to={to}
                      onClick={() => setMenuOpen(false)}
                      className={overrideTailwindClasses(
                        `${navItemDefault} ${
                          i === arr.length - 1 ? navItemCTAClass : navItemClass
                        }`
                      )}
                      activeClassName="font-semibold"
                      // Set this to match sub directories as well
                      partiallyActive={to !== "/"}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>

            {/* <CartButton /> */}

            {/* Mobile Menu toggle */}
            <button
              className="z-40 block menu__toggle lg:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span></span>
            </button>
          </div>
        </div>
      </div>
    </Headroom>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default memo(Header)
